import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import Card from "components/card";
import List from "components/list";
import { Link, graphql } from "gatsby";
import Denki from "components/denkiLoadable";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "electricbill"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcselection/electricbill.png"}) { ...eyecatchImg },
    wattchecker_hq: file(relativePath: { eq: "electric/wattchecker_hq.png"}) { ...normalImgWithHQ },
    browsing: file(relativePath: { eq: "electric/browsing.png"}) { ...normalImg },
    youtube: file(relativePath: { eq: "electric/youtube.png"}) { ...normalImg },
    gaming: file(relativePath: { eq: "electric/gaming.png"}) { ...normalImg },
    idle: file(relativePath: { eq: "electric/idle.png"}) { ...normalImg },
    sleep: file(relativePath: { eq: "electric/sleep.png"}) { ...normalImg },
    monitor_denki: file(relativePath: { eq: "electric/monitor_denki.png"}) { ...normalImg },
    browsingnote: file(relativePath: { eq: "electric/browsing-note.png"}) { ...normalImg },
    youtubenote: file(relativePath: { eq: "electric/youtube-note.png"}) { ...normalImg },
    idle_nogpu: file(relativePath: { eq: "electric/idle_nogpu.png"}) { ...normalImg },
    idle_gpu_no_output: file(relativePath: { eq: "electric/idle_gpu_no_output.png"}) { ...normalImg },
    cgrabohikaku: file(relativePath: { eq: "cover/gpu/grabo-hikaku.png"}) { ...eyecatchImg },
    charge: file(relativePath: { eq: "electric/charge.png"}) { ...normalImg },
    totalenergy: file(relativePath: { eq: "electric/totalenergy.png"}) { ...normalImg },
    batterycharge: file(relativePath: { eq: "electric/batterycharge.png"}) { ...normalImg },
    highwpc_webbrowse: file(relativePath: { eq: "electric/highwpc_webbrowse.png"}) { ...normalImg },
    ps5game: file(relativePath: { eq: "electric/ps5game.png"}) { ...normalImg },
    ccpuhikaku: file(relativePath: { eq: "cover/cpu/cpu-hikaku.png"}) { ...eyecatchImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`パソコンの電気代が簡単に！シュミレータと実測でわかる値と節約術`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="パソコンの電気代は？" mdxType="Image" />
    <p>{`パソコンの消費電力量はエアコンや電子レンジなどと比べると小さいが、日常的に使うものであるためトータルの電気コストは馬鹿にならない。`}</p>
    <p>{`また、たとえばデスクトップは100W、ノートは30Wが目安などと多くのWebサイトで書かれているが、これらの目安はデタラメである。パソコンの消費電力は種類や使用用途によって大きく左右されるためである。`}</p>
    <p>{`そこで今回はワットチェッカーを用いて様々な条件でパソコンの電気代を測り、そこに専門知識と合わせてパソコンにかかる消費電力及び、電気代を浮き彫りにしていく。`}</p>
    <p>{`検証結果をもとに電気代チェッカーも作成したので、電気代のあたりをつけたい場合は活用して欲しい。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "簡易パソコン電気代チェッカー",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E7%B0%A1%E6%98%93%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E9%9B%BB%E6%B0%97%E4%BB%A3%E3%83%81%E3%82%A7%E3%83%83%E3%82%AB%E3%83%BC",
        "aria-label": "簡易パソコン電気代チェッカー permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`簡易パソコン電気代チェッカー`}</h2>
    <p>{`電気代の目安を知りたい、あるいは本記事を読んだ上で自分で電気代の計算をしてみたいという方に向け、電気代チェッカーを作成した。`}</p>
    <p>{`簡易チェック表から消費電力の目安を自動入力するか、手動入力をして電気代を推定してみよう。`}</p>
    <p>{`ここでスタンダードノートは15.6インチ程度のノート、軽作業はYoutubeの閲覧、ネットショッピング、Office作業などを指す。また、ゲームは高度な3Dゲームを指し、基本的にはグラフィックボードの搭載を前提に考えている。`}</p>
    <p>{`ただし、消費電力の項目は自分の環境に応じて自由に設定できる他、その他の項目に調整用として任意の項目を追加できるようにしている。本記事を読めば勘所はある程度掴めるはずである。`}</p>
    <Denki mdxType="Denki" />
    <h2 {...{
      "id": "消費電力計測のためワットチェッカーを準備",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%B6%88%E8%B2%BB%E9%9B%BB%E5%8A%9B%E8%A8%88%E6%B8%AC%E3%81%AE%E3%81%9F%E3%82%81%E3%83%AF%E3%83%83%E3%83%88%E3%83%81%E3%82%A7%E3%83%83%E3%82%AB%E3%83%BC%E3%82%92%E6%BA%96%E5%82%99",
        "aria-label": "消費電力計測のためワットチェッカーを準備 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`消費電力計測のためワットチェッカーを準備`}</h2>
    <p>{`家にゲーミングデスクトップパソコンとテレワーク用に13.3インチのモバイルノートパソコンが一台あり、これらの電気代を測っていきたいので`}<a parentName="p" {...{
        "href": "https://amzn.to/3gRYzm7",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`ラトックシステム Bluetooth ワットチェッカー`}</a>{`を購入した。`}</p>
    <p>{`値段は6500円と高かったが、Bluetoothでスマホのアプリと接続するとリアルタイムで消費電力を測ることができるスグれモノである。全データ計測中2回ほど謎の再起動が走って安定性には若干欠けたが、まあ今回の消費電力を測る用途には十分であったので良しとする。`}</p>
    <Image {...props} name="wattchecker_hq" alt="ラトックシステムのワットチェッカー" mdxType="Image" />
    <p>{`スクリーンショットにあるように、`}<strong parentName="p">{`現在のワット数をリアルタイムで見ることができる他、消費電力の時系列グラフも確認することができる`}</strong>{`。`}</p>
    <p>{`これにより、例えばWebページのリンクをクリックした瞬間など、より細かいところまで正確に消費電力を確認することができる。`}</p>
    <p>{`このワットチェッカーで消費電力を求め、最終的に電気代まで求めていく。`}</p>
    <h2 {...{
      "id": "電気代は1kWh-27円として計算する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E9%9B%BB%E6%B0%97%E4%BB%A3%E3%81%AF1kWh-27%E5%86%86%E3%81%A8%E3%81%97%E3%81%A6%E8%A8%88%E7%AE%97%E3%81%99%E3%82%8B",
        "aria-label": "電気代は1kWh 27円として計算する permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`電気代は1kWh 27円として計算する`}</h2>
    <p>{`次に、今回想定する電気代の基準について説明する。メジャーどころの東京電力と東京ガスの電力量料金を見ると次のように書かれている。`}</p>
    <p>{`まずは`}<a parentName="p" {...{
        "href": "https://home.tokyo-gas.co.jp/power/ryokin/menu/basic.html",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`東京ガス`}</a>{`の料金体系`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>段階</th>
      <th>使用範囲</th>
      <th>kWh当たりの料金</th>
      <th>累積電気代</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>基本料金</td>
      <td>40A契約電流</td>
      <td></td>
      <td>1144円</td>
    </tr>
    <tr>
      <td>第1段階料金</td>
      <td>120kWhまで</td>
      <td>19.78円</td>
      <td>1144円~</td>
    </tr>
    <tr>
      <td>第2段階料金</td>
      <td>120-300kWhまで</td>
      <td>25.29円</td>
      <td>3518円~</td>
    </tr>
    <tr>
      <td>第3段階料金</td>
      <td>300kWhから</td>
      <td>27.36円</td>
      <td>8070円~</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`次に`}<a parentName="p" {...{
        "href": "https://www.tepco.co.jp/ep/private/plan/chargelist01.html",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`東京電力`}</a>{`の料金、同様の段階別料金体系だが若干高い。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>段階</th>
      <th>使用範囲</th>
      <th>kWh当たりの料金</th>
      <th>累積電気代~</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>基本料金</td>
      <td>40A契約電流</td>
      <td></td>
      <td>1144円</td>
    </tr>
    <tr>
      <td>第1段階料金</td>
      <td>120kWhまで</td>
      <td>19.88円</td>
      <td>1144円~</td>
    </tr>
    <tr>
      <td>第2段階料金</td>
      <td>120-300kWhまで</td>
      <td>26.48円</td>
      <td>3530円~</td>
    </tr>
    <tr>
      <td>第3段階料金</td>
      <td>300kWhから</td>
      <td>30.57円</td>
      <td>8296円~</td>
    </tr>
  </tbody>
    </BasicTable>
    <p><strong parentName="p">{`電気代は使うほど割高になるように設計されており`}</strong>{`、毎月の電気代が8000円以上かかる人は1kWh増えるたびに東京ガスなら27円、東京電力ならば30円がかかってくる。`}</p>
    <p>{`単身世帯か2人世帯の場合の場合ならば第二段階料金の25，26円の人も多いだろう。`}</p>
    <p>{`様々なケースがあるが、ここでは`}<strong parentName="p"><em parentName="strong">{`単純のため、1kWhあたり27円として電気代を計算していく`}</em></strong>{`ことにする。`}</p>
    <h2 {...{
      "id": "デスクトップパソコンの消費電力",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E6%B6%88%E8%B2%BB%E9%9B%BB%E5%8A%9B",
        "aria-label": "デスクトップパソコンの消費電力 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`デスクトップパソコンの消費電力`}</h2>
    <p>{`まずはデスクトップパソコンのスペックは下記の通りである。`}</p>
    <AccentBox title="デスクトップパソコンのスペック" mdxType="AccentBox">
  <li><span className="bold">CPU</span>・・・Core i5 8400 (<b>TDP:65W</b>)</li>
  <li><span className="bold">GPU</span>・・・Geforce GTX 1060 6GB (<b>TDP: 120W</b>)</li>
  <li><span className="bold">電源</span>・・・<b>400W(80Plus)</b></li>
  <li><span className="bold">メモリ</span>・・・16GB</li>
  <li><span className="bold">ストレージ</span>・・・SSD512GB</li>
  <li><span className="bold">OS</span>・・・Windows 10</li>
    </AccentBox>
    <p>{`やや古いパソコンであるが、重要なのはCPU、GPU、電源である。`}</p>
    <p>{`TDPとはThermal Design Powerの略で熱設計電力と日本語では訳される。TDPは設計者が放熱設計を行う時に参考にする値で、`}<strong parentName="p"><em parentName="strong">{`おおよそCPU/GPUが使う最大の電力`}</em></strong>{`となる。`}</p>
    <p>{`TDP65WのCPUは、アイドル時には数ワット程度しか電力を使わないが、負荷の高い計算処理を行った時には65W近い消費電力を使うことになる。`}</p>
    <p>{`それではこのスペックで様々な作業を行い電気代を測っていく。`}</p>
    <h3 {...{
      "id": "ネットサーフィン中は45W程度",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%8D%E3%83%83%E3%83%88%E3%82%B5%E3%83%BC%E3%83%95%E3%82%A3%E3%83%B3%E4%B8%AD%E3%81%AF45W%E7%A8%8B%E5%BA%A6",
        "aria-label": "ネットサーフィン中は45W程度 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ネットサーフィン中は45W程度`}</h3>
    <Image {...props} name="browsing" alt="Webブラウジング中の消費電力" mdxType="Image" />
    <p>{`Googleで検索して、検索結果のリンクを辿る動作を繰り返したところ図のようになった。`}</p>
    <p>{`いくつか60Wを超えるピークがあるが、ここが新しいページをクリックして開いた時である。`}</p>
    <p>{`新しいページを開くと、HTMLファイルや画像のダウンロード、描画、ページの初期化プログラムなどが走りCPUの使用率が一時的に上昇し、その結果消費電力が一時的に急増する。`}</p>
    <p>{`それでも常にクリックし続けるわけではないので、`}<strong parentName="p"><em parentName="strong">{`平均すると45W程度になる`}</em></strong>{`と思われる。1日3時間のネットサーフィンで1ヵ月の電気代は109円となる。`}</p>
    <h3 {...{
      "id": "Youtubeは43W程度でブラウジング以下",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Youtube%E3%81%AF43W%E7%A8%8B%E5%BA%A6%E3%81%A7%E3%83%96%E3%83%A9%E3%82%A6%E3%82%B8%E3%83%B3%E3%82%B0%E4%BB%A5%E4%B8%8B",
        "aria-label": "Youtubeは43W程度でブラウジング以下 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Youtubeは43W程度でブラウジング以下`}</h3>
    <Image {...props} name="youtube" alt="Youtube視聴中の消費電力" mdxType="Image" />
    <p>{`次にYoutubeをFullHDで再生した時の消費電力を測ってみた。`}</p>
    <p>{`Youtubeの再生は動画ファイルを定期的にダウンロード、ダウンロードした動画をデコードして再生という負荷が多少高そうなプロセスを経るため、少なくとも検索したサイトをだらだらと見ているよりは電力を食うと予想していたが、`}<strong parentName="p"><em parentName="strong">{`予想に反して電力をあまり消費しない結果`}</em></strong>{`となった。`}</p>
    <p>{`パソコンにはデコード専用のチップが搭載されているのだが、これが上手く働いて低消費電力に抑えられているのではないかと予想できる。`}</p>
    <p>{`また、`}<strong parentName="p">{`4K動画も同様に再生してみたが、消費電力に変化は見られなかった`}</strong>{`。`}</p>
    <h3 {...{
      "id": "ゲーム中は200W超まで上昇",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B2%E3%83%BC%E3%83%A0%E4%B8%AD%E3%81%AF200W%E8%B6%85%E3%81%BE%E3%81%A7%E4%B8%8A%E6%98%87",
        "aria-label": "ゲーム中は200W超まで上昇 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ゲーム中は200W超まで上昇`}</h3>
    <Image {...props} name="gaming" alt="ゲーム中の消費電力" mdxType="Image" />
    <p>{`次にファイナルファンタジーXVやフォートナイトといったゲームを遊んでみたところ、瞬く間に消費電力が上昇し、`}<strong parentName="p"><em parentName="strong">{`FF XVは200W、フォートナイトは平均すると150W程度`}</em></strong>{`となった。図はFFXVのもの。`}</p>
    <p>{`FF XV起動中はCPU、GPUの負荷がともにほぼ100%に近いところまでおり、このパソコンの限界に達した時が200W程度ということが判明した。`}</p>
    <p>{`GPUとCPUのTDPが合わせて185Wであり、これに電源の効率やその他のパーツが使用する電力を加味すると200W強は妥当と見なせるだろう。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`ゲームを毎日3時間遊ぶと、1ヵ月で90時間、18kWhとなるため、大体電気代は月に450円`}</em></strong>{`といったところである。`}</p>
    <h3 {...{
      "id": "つけっぱなしは38W、年間3600円の損失",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%A4%E3%81%91%E3%81%A3%E3%81%B1%E3%81%AA%E3%81%97%E3%81%AF38W%E3%80%81%E5%B9%B4%E9%96%933600%E5%86%86%E3%81%AE%E6%90%8D%E5%A4%B1",
        "aria-label": "つけっぱなしは38W、年間3600円の損失 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`つけっぱなしは38W、年間3600円の損失`}</h3>
    <Image {...props} name="idle" alt="ゲーム中の消費電力" mdxType="Image" />
    <p>{`次にパソコンを何もせずにただ放置した時(アイドル時)を測ってみたところ平均すると38Wであった。`}</p>
    <p>{`つまり`}<strong parentName="p">{`パソコンを放置して寝ると10時間で380Wh、電気代にすると10円を支払っていることになる`}</strong>{`。`}</p>
    <p>{`月にすると300円、`}<strong parentName="p"><em parentName="strong">{`年間3600円になるので、電気はこまめにスリープ状態にしておいた方が良い`}</em></strong>{`だろう。`}</p>
    <h3 {...{
      "id": "スリープ中の消費電力は13W程度",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B9%E3%83%AA%E3%83%BC%E3%83%97%E4%B8%AD%E3%81%AE%E6%B6%88%E8%B2%BB%E9%9B%BB%E5%8A%9B%E3%81%AF13W%E7%A8%8B%E5%BA%A6",
        "aria-label": "スリープ中の消費電力は13W程度 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`スリープ中の消費電力は1.3W程度`}</h3>
    <Image {...props} name="sleep" alt="スリープ中の消費電力" mdxType="Image" />
    <p>{`Windowsのスリープは非常に優秀で、わずか1.3Wしか電力を消費しなかった。`}<strong parentName="p"><em parentName="strong">{`1か月間放置したとして、電気代は僅かに25円となる`}</em></strong>{`。`}</p>
    <p>{`ちなみに電源を切っていても実は同じぐらいの電力を垂れ流していたので、この`}<strong parentName="p"><em parentName="strong">{`25円を削減しようとするにはコンセントから電源プラグを抜くしかない`}</em></strong>{`。`}</p>
    <h3 {...{
      "id": "モニタの消費電力は238インチで10W",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A2%E3%83%8B%E3%82%BF%E3%81%AE%E6%B6%88%E8%B2%BB%E9%9B%BB%E5%8A%9B%E3%81%AF238%E3%82%A4%E3%83%B3%E3%83%81%E3%81%A710W",
        "aria-label": "モニタの消費電力は238インチで10W permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`モニタの消費電力は23.8インチで10W`}</h3>
    <Image {...props} name="monitor_denki" alt="モニターの電力消費量" mdxType="Image" />
    <p>{`次にモニターの消費電力を調べた。私の使っているモニターは23.8インチのFullHDモニターである。`}</p>
    <p>{`図は輝度を100段階中の5から100へ徐々に上げていった時の消費電力の推移である。`}<strong parentName="p"><em parentName="strong">{`輝度が低い時は7W程度と低いが、輝度が最高となると2倍超の15Wまで上昇した`}</em></strong>{`。40程度で使っている人が多いとすると`}<strong parentName="p"><em parentName="strong">{`一般的には10Wぐらい`}</em></strong>{`ではないだろうか。`}</p>
    <p><strong parentName="p">{`1日3時間モニターを付けるとすると、1か月の電気代は24円`}</strong>{`となる。意外とつけっぱなしでも良いのではないかと思える。`}</p>
    <h3 {...{
      "id": "デスクトップの1ヵ月の電気代まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%81%AE1%E3%83%B5%E6%9C%88%E3%81%AE%E9%9B%BB%E6%B0%97%E4%BB%A3%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "デスクトップの1ヵ月の電気代まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`デスクトップの1ヵ月の電気代まとめ`}</h3>
    <p>{`では最後に早見表として、これまでの実験結果を用い、時間ごとに1ヵ月(30日として計算)の電気代をまとめてみる。スリープ中以外はモニタの消費電力を10Wとして加算した上で計算する。`}</p>
    <p>{`デュアルディスプレイ環境の人や、40インチの大型ディスプレイを使っている人は前提が変わってくるので頭の中でもう少し計算する必要はある。また、先にも述べたが電気代の前提は1kWH 27円である。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th></th>
      <th>1時間</th>
      <th>3時間</th>
      <th>8時間</th>
      <th>24時間</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>ネットサーフィン</td>
      <td>44円</td>
      <td>133円</td>
      <td>356円</td>
      <td></td>
    </tr>
    <tr>
      <td>Youtube</td>
      <td>43円</td>
      <td>129円</td>
      <td>343円</td>
      <td></td>
    </tr>
    <tr>
      <td>ゲーム</td>
      <td>170円</td>
      <td>510円</td>
      <td>1360円</td>
      <td>4082円</td>
    </tr>
    <tr>
      <td>アイドル</td>
      <td>39円</td>
      <td>116円</td>
      <td>311円</td>
      <td>933円</td>
    </tr>
    <tr>
      <td>スリープ</td>
      <td>1円</td>
      <td>3円</td>
      <td>8円</td>
      <td>25円</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`ゲームを24時間やり続ける人はいないが、仮想通貨のマイニングを行っていると同等ぐらいになるので、その参考と思ってもらえれば良い。`}</p>
    <h2 {...{
      "id": "ノートパソコンの消費電力",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E6%B6%88%E8%B2%BB%E9%9B%BB%E5%8A%9B",
        "aria-label": "ノートパソコンの消費電力 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートパソコンの消費電力`}</h2>
    <p>{`次に筆者がテレワークで使用しているノートパソコンの消費電力を計測してみた。このパソコンのスペックは以下の通りである。`}</p>
    <AccentBox title="ノートパソコンのスペック" mdxType="AccentBox">
  <li><span className="bold">CPU</span>・・・Core i7 8565U (<b>TDP:15W</b>)</li>
  <li><span className="bold">モニター</span>・・・13.3インチ</li>
  <li><span className="bold">GPU</span>・・・無し</li>
  <li><span className="bold">電源</span>・・・記載なし</li>
  <li><span className="bold">メモリ</span>・・・8GB</li>
  <li><span className="bold">ストレージ</span>・・・SSD 512GB</li>
  <li><span className="bold">OS</span>・・・Windows 10</li>
    </AccentBox>
    <h3 {...{
      "id": "各操作中の消費電力",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%90%84%E6%93%8D%E4%BD%9C%E4%B8%AD%E3%81%AE%E6%B6%88%E8%B2%BB%E9%9B%BB%E5%8A%9B",
        "aria-label": "各操作中の消費電力 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`各操作中の消費電力`}</h3>
    <Image {...props} name="browsingnote" alt="ブラウジング中の電力消費量" mdxType="Image" />
    <p>{`まず、ネットサーフィン中の消費電力は上のようになった。40Wを超えているところはクリックをしたタイミングで、デスクトップの時と変わらず`}<strong parentName="p">{`他のページへ遷移したときに消費電力が大きく上昇`}</strong>{`した。`}</p>
    <p><strong parentName="p">{`平均すると18W程度であり、平日毎日8時間テレワークしたとすると、月で80円ぐらい`}</strong>{`である。`}</p>
    <p>{`また、`}<strong parentName="p">{`アイドル時は、このグラフの最低値から推測が付くと思うが10W程度`}</strong>{`であった。アイドル時に`}<strong parentName="p"><em parentName="strong">{`デスクトップとノートで消費電力に4倍の開きが出た`}</em></strong>{`ことは驚きである。さらにモニタ含めての消費電力なのでそれを含めると5倍近い開きとなる。`}</p>
    <p>{`CPUのTDPが低く、1ワット当たりのCPU能力を表す`}<em parentName="p">{`ワットパフォーマンス`}</em>{`が高いことがまず要因として挙げられる。`}</p>
    <p>{`他にもデスクトップパソコンでは400Wの電源を使っていて、普段使いでは50W程度しか使っていないので熱効率が悪いという点もあるかもしれない。また、メーカーがVAIOなのでそもそもの熱効率が良い影響も考えられる。`}</p>
    <Image {...props} name="youtubenote" alt="ノートパソコンでのYoutubeの消費電力" mdxType="Image" />
    <p>{`次にYoutubeはかなり効率的にストリーミングを行っており、13W程度であった。また、スリープ時は私のパソコンがスリープモードに対応していないため測ることができなかったが、13インチのDellパソコンを計測している人のデータによると0.6Wを切る程度であったのでこの値を採用する。`}</p>
    <h3 {...{
      "id": "ノートパソコンの１ヵ月の電気代",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%EF%BC%91%E3%83%B5%E6%9C%88%E3%81%AE%E9%9B%BB%E6%B0%97%E4%BB%A3",
        "aria-label": "ノートパソコンの１ヵ月の電気代 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートパソコンの１ヵ月の電気代`}</h3>
    <p>{`上記の結果からノートパソコンの電気代をまとめると次のようになった。
ゲーミングノートは持っていないため計測できていないが、おおよそCPUのTDPとGPUのTDPを合わせ数をやや超えるぐらいが消費電力となるので、ゲーム中は150Wとして計算している。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>時間</th>
      <th>1時間</th>
      <th>3時間</th>
      <th>8時間</th>
      <th>24時間</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>ネットサーフィン</td>
      <td>15円</td>
      <td>44円</td>
      <td>117円</td>
      <td>349円</td>
    </tr>
    <tr>
      <td>Youtube</td>
      <td>11円</td>
      <td>32円</td>
      <td>84円</td>
      <td>257円</td>
    </tr>
    <tr>
      <td>ゲーム</td>
      <td>121円</td>
      <td>364円</td>
      <td>972円</td>
      <td>2916円</td>
    </tr>
    <tr>
      <td>アイドル</td>
      <td>8円</td>
      <td>24円</td>
      <td>65円</td>
      <td>194円</td>
    </tr>
    <tr>
      <td>スリープ</td>
      <td>0.5円</td>
      <td>1.4円</td>
      <td>3.7円</td>
      <td>11.5円</td>
    </tr>
  </tbody>
    </BasicTable>
    <p><strong parentName="p"><em parentName="strong">{`デスクトップパソコンと比べて何もしていない時の消費電力が大幅に低い`}</em></strong>{`ことが特徴といえる。省エネノートで画面の輝度を十分に抑えると5W程度までは下げられそうである。`}</p>
    <p>{`しかし、いくら何でもデスクトップと差がありすぎないか？と少し気になったので、現状のパーツの差異であるGPUを取り外して次に実験してみた。`}</p>
    <h3 {...{
      "id": "コンセントを挿しっぱなしでもノートパソコンは電力を消費しなかった",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B3%E3%83%B3%E3%82%BB%E3%83%B3%E3%83%88%E3%82%92%E6%8C%BF%E3%81%97%E3%81%A3%E3%81%B1%E3%81%AA%E3%81%97%E3%81%A7%E3%82%82%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AF%E9%9B%BB%E5%8A%9B%E3%82%92%E6%B6%88%E8%B2%BB%E3%81%97%E3%81%AA%E3%81%8B%E3%81%A3%E3%81%9F",
        "aria-label": "コンセントを挿しっぱなしでもノートパソコンは電力を消費しなかった permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`コンセントを挿しっぱなしでもノートパソコンは電力を消費しなかった`}</h3>
    <p>{`電源を切った状態で、消費電力を計測すると0Wであった。私の計測器が0.3W以下の電力を計測できないということはあるのだが、`}<strong parentName="p"><em parentName="strong">{`ノートパソコンの場合は電源ケーブルを挿しっぱなしでも構わない`}</em></strong>{`という結果となった。`}</p>
    <p>{`私のデスクトップパソコンは電源を切っても、薄っすらとLEDが光っている部分があるので、省エネな作りをしているデスクトップであるならば同様にほぼ電力を消費しないのかもしれない。`}</p>
    <h2 {...{
      "id": "GPUを付けているだけで消費電力が増加",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#GPU%E3%82%92%E4%BB%98%E3%81%91%E3%81%A6%E3%81%84%E3%82%8B%E3%81%A0%E3%81%91%E3%81%A7%E6%B6%88%E8%B2%BB%E9%9B%BB%E5%8A%9B%E3%81%8C%E5%A2%97%E5%8A%A0",
        "aria-label": "GPUを付けているだけで消費電力が増加 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`GPUを付けているだけで消費電力が増加`}</h2>
    <p>{`デスクトップパソコンとノートパソコンの何もしていない状態、すなわちアイドル時の消費電力の違いで気になったのがGPUの有無であったので、GPUを外して再度デスクトップの消費電力を測ってみたところ以下のようになった。`}</p>
    <Image {...props} name="idle_nogpu" alt="GPU無しのデスクトップのアイドル時の消費電力" mdxType="Image" />
    <p>{`結果38Wだったのが28Wまで低下した。ただ、GPUは2つのモニターが取り付けられていて、ここでは一つであったので公平な条件ではない。`}</p>
    <p>{`そこで、GPUを付けているものの、GPUを使用して出力は行わない状態でアイドル時の消費電力を測ってみたところ次のようになった。`}</p>
    <Image {...props} name="idle_gpu_no_output" alt="GPU付属だがアウトプット無しの消費電力" mdxType="Image" />
    <p>{`この結果約34Wの電力を消費していることが分かった。すなわち`}<strong parentName="p"><em parentName="strong">{`何も役に立っていない状態で、GPUを取り付けているだけで4Wの電力を消費していることになる`}</em></strong>{`。`}</p>
    <p>{`毎日3時間パソコンを使ったとすると、`}<em parentName="p">{`5年で600円の差`}</em>{`となる。`}<strong parentName="p">{`GPUを使うかどうか分からない場合は`}<em parentName="strong">{`最初から搭載するよりは、必要になってから増設した方が電気代の面からも良い`}</em></strong>{`と言える。`}</p>
    <h2 {...{
      "id": "ノートとデスクトップの5年の電気代を比較する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%81%A8%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%81%AE5%E5%B9%B4%E3%81%AE%E9%9B%BB%E6%B0%97%E4%BB%A3%E3%82%92%E6%AF%94%E8%BC%83%E3%81%99%E3%82%8B",
        "aria-label": "ノートとデスクトップの5年の電気代を比較する permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートとデスクトップの5年の電気代を比較する`}</h2>
    <p>{`パソコンのライフサイクルはおよそ5年なので、`}<strong parentName="p"><em parentName="strong">{`5年間のトータルでの電気代を考えることはノートかデスクトップパソコンのどちらを購入すべきかの重要な意思決定要素`}</em></strong>{`となる。`}</p>
    <p>{`そこでオフィス作業やネットサーフィン、動画視聴などの軽い作業と、重い作業の代表例である3Dゲームのそれぞれで5年間の電気代を算出する。`}</p>
    <h3 {...{
      "id": "テレワークだと5年でノートパソコンが1万円お得",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%86%E3%83%AC%E3%83%AF%E3%83%BC%E3%82%AF%E3%81%A0%E3%81%A85%E5%B9%B4%E3%81%A7%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%8C1%E4%B8%87%E5%86%86%E3%81%8A%E5%BE%97",
        "aria-label": "テレワークだと5年でノートパソコンが1万円お得 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`テレワークだと5年でノートパソコンが1万円お得`}</h3>
    <p>{`まず、ノートとデスクトップともGPUを付けないパソコンを想定した場合、5年の電気代は次のようになった。ほとんど消費電力が変わらなかったネットサーフィン、Excel、PowerPointなどの事務作業、動画視聴は"軽い作業・動画"として一つにまとめた。`}</p>
    <p>{`また、デスクトップパソコンのモニタの電力は10Wを想定しており、一つ繋いでいるものとして結果に含める。まずはデスクトップを5年間使った時の電気代から。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>時間</th>
      <th>1時間</th>
      <th>3時間</th>
      <th>8時間</th>
      <th>24時間</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>軽い作業・動画(45W)</td>
      <td>2217円</td>
      <td>6652円</td>
      <td>17739円</td>
      <td>53217円</td>
    </tr>
    <tr>
      <td>アイドル(38W)</td>
      <td>1872円</td>
      <td>5617円</td>
      <td>14979円</td>
      <td>44939円</td>
    </tr>
    <tr>
      <td>スリープ(1.3W)</td>
      <td>64円</td>
      <td>192円</td>
      <td>512円</td>
      <td>1537円</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`次にノートパソコンを5年間使った時の電気代。`}<strong parentName="p">{`軽い作業・動画の欄にはデスクトップパソコンと比べた時の差も記入`}</strong>{`した。`}</p>
    <BasicTable mdxType="BasicTable">
 <thead>
    <tr>
      <th>時間</th>
      <th>1時間</th>
      <th>3時間</th>
      <th>8時間</th>
      <th>24時間</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>軽い作業・動画(約15W)</td>
      <td>985円<br />(-1231円)</td>
      <td>2956円<br />(-3695円)</td>
      <td>7884円<br />(-9855円)</td>
      <td>23652円<br />(-29565円)</td>
    </tr>
    <tr>
      <td>アイドル(10W)</td>
      <td>493円</td>
      <td>1478円</td>
      <td>3942円</td>
      <td>11826円</td>
    </tr>
    <tr>
      <td>スリープ(0.6W)</td>
      <td>29円</td>
      <td>89円</td>
      <td>236円</td>
      <td>709円</td>
    </tr>
  </tbody>
    </BasicTable>
    <p><strong parentName="p"><em parentName="strong">{`テレワークなどで1日平均8時間程度仕事をする人を想定すると、5年間トータルで1万円近く電気代が浮く`}</em></strong>{`ことになる。`}</p>
    <p>{`ただデスクトップパソコンは値段の割に高性能でかつ、故障時にもパーツの交換だけで済むため、電気代のマイナスがあったとしても割安なのはデスクトップパソコンの方である。`}</p>
    <h3 {...{
      "id": "ゲームはGPUのTDPに大きく左右される",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B2%E3%83%BC%E3%83%A0%E3%81%AFGPU%E3%81%AETDP%E3%81%AB%E5%A4%A7%E3%81%8D%E3%81%8F%E5%B7%A6%E5%8F%B3%E3%81%95%E3%82%8C%E3%82%8B",
        "aria-label": "ゲームはGPUのTDPに大きく左右される permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ゲームはGPUのTDPに大きく左右される`}</h3>
    <p>{`次にゲームの消費電力だが、これはデスクトップかノートかというよりは、GPUの省エネ性能及び、どのぐらい高画質でゲーム映像を楽しめるかに消費電力が大きく依存してくる。`}</p>
    <p>{`下記にGPU性能比較表を紹介するが、Max-Qというノートパソコンの省エネモデルは80WのTDPだが、デスクトップ用のGeforceは350WのTDPというものもある。`}</p>
    <Card {...props} title="GPU性能比較表" description="GPUの性能やTDPの情報を表として一覧できます。" image="cgrabohikaku" path="/gpu/" mdxType="Card" />
    <p>{`GPUとCPUの組み合わせ、その時に最大限高画質でゲームをプレイしたときの予想平均消費電力と1日平均3時間プレイしたときの5年間の電気代は次のようになる。`}</p>
    <BasicTable mdxType="BasicTable">
 <thead>
    <tr>
      <th>CPU,GPUの組み合わせ</th>
      <th>ゲーム時の消費電力</th>
      <th>5年間の電気代</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Max-Q(80W) + ゲーミングノートCPU(45W)</td>
      <td>145W</td>
      <td>21400円</td>
    </tr>
    <tr>
      <td>ノート用通常(120W) + ゲーミングノートCPU(45W)</td>
      <td>190W(省エネのデスクトップもこの程度)</td>
      <td>28000円</td>
    </tr>
    <tr>
      <td>ミドルエンドGPU(200W) + デスクトップ用通常CPU(65W)</td>
      <td>300W</td>
      <td>44300円</td>
    </tr>
    <tr>
      <td>デスクトップ用超ハイエンドGPU(350W)+CPU(125W)</td>
      <td>550W</td>
      <td>81300円</td>
    </tr>
  </tbody>
    </BasicTable>
    <p><strong parentName="p">{`Max-Qは薄型のパソコンに搭載され、`}<em parentName="strong">{`通常のノートパソコンと比べると6500円程度トータルで電気代が安くなる`}</em></strong>{`。それなりにまとまった金額なので、Max-Qか通常かで悩んだときは電気代も加味して購入を決定すべきだろう。`}</p>
    <p>{`RTX 3090のような超ハイエンドのGPUは値段も30万近くするが、同時に電気代も電気ストーブ並みにかかってくるので2重でコストパフォーマンスが悪い。ただ、実際`}<strong parentName="p"><em parentName="strong">{`冬は暖房器具としても使うのならばコスパが良いかも`}</em></strong>{`しれない。`}</p>
    <h2 {...{
      "id": "充電して使った方がノートパソコンの電気代は安いのか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%85%85%E9%9B%BB%E3%81%97%E3%81%A6%E4%BD%BF%E3%81%A3%E3%81%9F%E6%96%B9%E3%81%8C%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E9%9B%BB%E6%B0%97%E4%BB%A3%E3%81%AF%E5%AE%89%E3%81%84%E3%81%AE%E3%81%8B%EF%BC%9F",
        "aria-label": "充電して使った方がノートパソコンの電気代は安いのか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`充電して使った方がノートパソコンの電気代は安いのか？`}</h2>
    <p>{`今までの計測は全てノートパソコンをコンセントにつなぐケースを考えてきた。しかしバッテリーを充電して使う場合は電気代は変わるのだろうか？`}</p>
    <p>{`コンセントからバッテリーを充電する時にエネルギーをロスすることが考えられるので、この場合はコンセントから直接電力供給を受けた方がトータルの電力量は少なそうである。`}</p>
    <p>{`逆にバッテリーからノートパソコンを起動した方が電圧が低い分効率的に電力を供給できるのではないか？100Vというコンセントの高電圧が電力の供給効率を下げてはいないか？などと考えるとバッテリーから起動した方が電力量は減らせるのではとも考えられる。`}</p>
    <p>{`これを実験したい。`}</p>
    <h3 {...{
      "id": "充電によるエネルギーロスはほぼ無い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%85%85%E9%9B%BB%E3%81%AB%E3%82%88%E3%82%8B%E3%82%A8%E3%83%8D%E3%83%AB%E3%82%AE%E3%83%BC%E3%83%AD%E3%82%B9%E3%81%AF%E3%81%BB%E3%81%BC%E7%84%A1%E3%81%84",
        "aria-label": "充電によるエネルギーロスはほぼ無い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`充電によるエネルギーロスはほぼ無い`}</h3>
    <Image {...props} name="charge" alt="充電開始" mdxType="Image" />
    <p>{`ノートパソコンの充電を開始したところ、約43Wで充電が開始された。そして40分程度たった時に、充電速度が落ち、30W程度となり、最終的に約2時間で79%になったところで終了した。`}</p>
    <p>{`この時の`}<em parentName="p">{`消費電力量のトータルは34.1Wh`}</em>{`であった。電気代にすると0.9円である。安い。`}</p>
    <Image {...props} name="totalenergy" alt="充電に使う消費電力量" mdxType="Image" />
    <p>{`一方で実際にバッテリーに溜まった電力量を調査した。`}</p>
    <Image {...props} name="batterycharge" alt="充電で実際に溜まった電力量" mdxType="Image" />
    <p>{`5%から79%までチャージされており、2420mWhから35810mWhにバッテリー残量が増加している。単位を変えると`}<em parentName="p">{`33.39Whチャージされている`}</em>{`ことになる。`}</p>
    <p>{`すなわち`}<strong parentName="p"><em parentName="strong">{`エネルギーロスはたったの0.71Wで、充電効率は98%という計算結果`}</em></strong>{`になった。非常に優秀であると言える。`}</p>
    <h3 {...{
      "id": "バッテリーの方が効率が良いという事は無い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%90%E3%83%83%E3%83%86%E3%83%AA%E3%83%BC%E3%81%AE%E6%96%B9%E3%81%8C%E5%8A%B9%E7%8E%87%E3%81%8C%E8%89%AF%E3%81%84%E3%81%A8%E3%81%84%E3%81%86%E4%BA%8B%E3%81%AF%E7%84%A1%E3%81%84",
        "aria-label": "バッテリーの方が効率が良いという事は無い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`バッテリーの方が効率が良いという事は無い`}</h3>
    <p>{`次にYoutubeを1時間再生し続けて、その時に消費した電力量を計測したところ、12.39Whであった。公平を期すために、バッテリーは最も消費する設定で行った。`}</p>
    <p>{`ACアダプターを付けて同様の計測したところ約13Whであったことから、`}<strong parentName="p"><em parentName="strong">{`バッテリーからの起動でもコンセントにつないだ状態であっても消費電力はほとんど変わらない`}</em></strong>{`と言える。`}</p>
    <p>{`ただ、バッテリーを節約モードにしてかつ輝度を低くしながら使うとバッテリーの持ちは良くなるので、電気代の観点からはバッテリーで起動した方が良いのかもしれない。ただ、手動で省エネモードに変えることもできるのでやはりどちらでもよいか。`}</p>
    <p>{`ともあれ結論として、`}<strong parentName="p"><em parentName="strong">{`バッテリーで駆動した場合とコンセントから駆動した場合で総消費電力は変わらない`}</em></strong>{`と言えるだろう。`}</p>
    <h2 {...{
      "id": "電源容量は使う最大電力の半分程度が良い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E9%9B%BB%E6%BA%90%E5%AE%B9%E9%87%8F%E3%81%AF%E4%BD%BF%E3%81%86%E6%9C%80%E5%A4%A7%E9%9B%BB%E5%8A%9B%E3%81%AE%E5%8D%8A%E5%88%86%E7%A8%8B%E5%BA%A6%E3%81%8C%E8%89%AF%E3%81%84",
        "aria-label": "電源容量は使う最大電力の半分程度が良い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`電源容量は使う最大電力の半分程度が良い`}</h2>
    <p>{`コンセントから得た交流を直流に変換してCPUやGPUなどのパーツに流すわけだが、この電気の変換効率がどれぐらい優れているかについて、`}<em parentName="p">{`80PLUS認証`}</em>{`というものが存在する。`}</p>
    <p>{`その表がこちらである。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
	<thead>
		<tr>
			<th></th>
			<th>負荷20%</th>
			<th>負荷50%</th>
			<th>負荷80%</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<th style={{
            backgroundColor: 'white',
            color: 'black'
          }}>STANDARD</th>
			<td>80%</td>
			<td>80%</td>
			<td>80%</td>
		</tr>
		<tr>
			<th style={{
            backgroundColor: '#cd7f32',
            color: 'black'
          }}>BRONZE</th>
			<td>82%</td>
			<td>85%</td>
			<td>82%</td>
		</tr>
		<tr>
			<th style={{
            backgroundColor: 'silver',
            color: 'black'
          }}>SILVER</th>
			<td>85%</td>
			<td>88%</td>
			<td>85%</td>
		</tr>
		<tr>
			<th style={{
            backgroundColor: 'gold',
            color: 'black'
          }}>GOLD</th>
			<td>87%</td>
			<td>90%</td>
			<td>87%</td>
		</tr>
		<tr>
			<th style={{
            backgroundColor: '#E5E4E2',
            color: 'black'
          }}>PLATINUM</th>
			<td>90%</td>
			<td>92%</td>
			<td>89%</td>
		</tr>
		<tr>
			<th style={{
            backgroundColor: '#DDD6E1',
            color: 'black'
          }}>TITANIUM</th>
			<td>92%</td>
			<td>94%</td>
			<td>90%</td>
		</tr>
	</tbody>
    </BasicTable>
    <p>{`BRONZEで400Wの電源容量ならば、50%の負荷、すなわちワットチェッカーが200Wをさしている時の電気変換効率は85%なので、170Wの電力がトータルで提供されることになる。`}</p>
    <p>{`ここで注目したいことは`}<strong parentName="p"><em parentName="strong">{`電源への負荷が50%の時に変換効率が最も高くなる`}</em></strong>{`ということである。`}</p>
    <p><a parentName="p" {...{
        "href": "https://linustechtips.com/topic/833155-psu-at-100-efficiency/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`PCの電気変換効率に関するスレッド`}</a>{`などを参考にしてもらえば分かるが、20%の負荷を切ると加速度的に変換効率が落ちている。`}</p>
    <p>{`400Wの10%は40W、すなわちYoutube等の鑑賞をしている時は、STANDARDな電源だと70%ぐらいの変換効率だと思われる。言い換えると12Wの電力を変換でロスしていることになる。`}</p>
    <p>{`あまり電源容量が低いと動作が安定しない恐れもあるので、`}<strong parentName="p"><em parentName="strong">{`パソコンの想定される最大消費電力の2倍程度の電源を搭載するのが良い`}</em></strong>{`とされている。`}</p>
    <p>{`パソコンの最大消費電力はCPUとGPUのTDPの和に他のパーツが使用する25W程度を加えて2倍すれば良いだろう。`}</p>
    <p>{`私のデスクトップパソコンの場合はCPU(65W TDP) + GPU(120W TDP) + 25W = 210Wなので、400Wの電源がちょうど良いというわけである。`}</p>
    <p>{`ただ、グラフィックボードを搭載しない場合は90Wとなるので180Wの電源が一番最適である。`}</p>
    <p>{`マウスコンピュータやパソコン工房などのBTOパソコンを扱うメーカーでは少なくとも300W程度の電源を積んでおり中々お目に掛かれないが、`}<strong parentName="p">{`デルやHPのスリム型デスクトップパソコンは正に180W程度の電源を積んでおり、省エネである`}</strong>{`ことが分かる。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`グラボを搭載しないことが分かっている場合は電源は低く抑えるべき`}</em></strong>{`である。`}</p>
    <p>{`ちなみに下記は750W PLATINUMの電源でWebサイトを閲覧しているものであるが、`}<strong parentName="p"><em parentName="strong">{`クリック処理など軽い処理が走るだけでワット数は100W近くと大きく跳ね上がる`}</em></strong>{`。細かな電力の制御ができないのである。`}</p>
    <Image {...props} name="highwpc_webbrowse" caption="電源容量が大きいと少ない負荷でもW数が跳ね上がる" mdxType="Image" />
    <h2 {...{
      "id": "パソコンの電気代に関するケーススタディ、QA",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E9%9B%BB%E6%B0%97%E4%BB%A3%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E3%82%B1%E3%83%BC%E3%82%B9%E3%82%B9%E3%82%BF%E3%83%87%E3%82%A3%E3%80%81QA",
        "aria-label": "パソコンの電気代に関するケーススタディ、QA permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコンの電気代に関するケーススタディ、QA`}</h2>
    <p>{`最後に様々な視点からパソコンの電気代に関する質問に答えていこうと思う。パソコン購入で悩んでいる人、電気代をより安くしたい人は参考にして欲しい。`}</p>
    <h3 {...{
      "id": "PS5とパソコンでのゲームではどちらが電気代が得か？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#PS5%E3%81%A8%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%A7%E3%81%AE%E3%82%B2%E3%83%BC%E3%83%A0%E3%81%A7%E3%81%AF%E3%81%A9%E3%81%A1%E3%82%89%E3%81%8C%E9%9B%BB%E6%B0%97%E4%BB%A3%E3%81%8C%E5%BE%97%E3%81%8B%EF%BC%9F",
        "aria-label": "PS5とパソコンでのゲームではどちらが電気代が得か？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PS5とパソコンでのゲームではどちらが電気代が得か？`}</h3>
    <Image {...props} name="ps5game" alt="PS5でゲームをしている時の消費電力" mdxType="Image" />
    <p>{`図はPS5でリターナルというFPSのゲームをした時の消費電力である。PS5の電源容量は350Wのため妥当な消費電力だといえる。`}</p>
    <p>{`ここで、PS5の能力はGeforce 2080Tiとほぼ等しく、Geforce 3060Tiよりも常に上のパフォーマンスを発揮しているが、これらのGPUのTDPはそれぞれ250Wと200Wである。`}</p>
    <p>{`すなわち、CPUなどと合わせればこれらを搭載した`}<strong parentName="p"><em parentName="strong">{`パソコンは300Wを超える電力を使うこととなり、これと比較すれば200WのPS5は断トツでエコ`}</em></strong>{`ということが言える。`}</p>
    <p>{`PS5クラスの画質をパソコンで実現しようとするとおよそ350W程度必要となることから、`}<strong parentName="p"><em parentName="strong">{`1日3時間程度ゲームを行う人は5年間で22000円の電気代の差が出る`}</em></strong>{`。`}</p>
    <p>{`PS5とパソコンで同じような楽しみ方をするならばPS5を購入すれば良いだろう。`}</p>
    <h3 {...{
      "id": "デスクトップパソコンは欲しいがノート並みの消費電力にできるか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AF%E6%AC%B2%E3%81%97%E3%81%84%E3%81%8C%E3%83%8E%E3%83%BC%E3%83%88%E4%B8%A6%E3%81%BF%E3%81%AE%E6%B6%88%E8%B2%BB%E9%9B%BB%E5%8A%9B%E3%81%AB%E3%81%A7%E3%81%8D%E3%82%8B%E3%81%8B%EF%BC%9F",
        "aria-label": "デスクトップパソコンは欲しいがノート並みの消費電力にできるか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`デスクトップパソコンは欲しいがノート並みの消費電力にできるか？`}</h3>
    <p>{`デスクトップ向けにはデスクトップ用のCPUが使われているが、`}<strong parentName="p"><em parentName="strong">{`一体型のパソコンや、ミニPCと言われるような筐体が小さいパソコンではTDPが15W程度のノート向けのCPUを搭載している機種も多い`}</em></strong>{`。`}</p>
    <p>{`これは小さい筐体にパーツを押し込める必要があるため、放熱設計が難しく、パワフルなCPUが使えないという理由であるが、同時に電気代が安くなるというメリットはある。`}</p>
    <p>{`下記のCPU性能比較表などを参考にして低消費電力のCPUを探して見て欲しい。`}</p>
    <Card {...props} title="CPU性能比較表" description="CPUの比較を簡単に！最新から定番まで400を超えるCPUからTDPやコア数等で絞り込み。" image="ccpuhikaku" path="/cpu/" mdxType="Card" />
    <p>{`逆に`}<strong parentName="p">{`15.6インチのゲーミングノートでは、デスクトップ用のCPUを積んでいることもあるので電気代の観点からは注意が必要`}</strong>{`である。`}</p>
    <h3 {...{
      "id": "ノートパソコンの充電1回に一体いくらかかるのか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E5%85%85%E9%9B%BB1%E5%9B%9E%E3%81%AB%E4%B8%80%E4%BD%93%E3%81%84%E3%81%8F%E3%82%89%E3%81%8B%E3%81%8B%E3%82%8B%E3%81%AE%E3%81%8B%EF%BC%9F",
        "aria-label": "ノートパソコンの充電1回に一体いくらかかるのか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートパソコンの充電1回に一体いくらかかるのか？`}</h3>
    <p><strong parentName="p"><em parentName="strong">{`1円超えるぐらい`}</em></strong>{`である。モバイルノートのバッテリーは40Whぐらいの容量である。一方で電気代は1kWh、すなわち1000Whで27円である。27円に40/1000で約1円ということになる。`}</p>
    <p>{`ちなみに先の節で話をしたが、コンセントから充電時のエネルギーロスはほとんどないので考えなくても良い。`}</p>
    <h3 {...{
      "id": "スリープより電源オフの方が良くないか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B9%E3%83%AA%E3%83%BC%E3%83%97%E3%82%88%E3%82%8A%E9%9B%BB%E6%BA%90%E3%82%AA%E3%83%95%E3%81%AE%E6%96%B9%E3%81%8C%E8%89%AF%E3%81%8F%E3%81%AA%E3%81%84%E3%81%8B%EF%BC%9F",
        "aria-label": "スリープより電源オフの方が良くないか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`スリープより電源オフの方が良くないか？`}</h3>
    <p>{`自分の環境で言うと何故かスリープにしている時の方が、電源オフにしている時よりも消費電力がわずかに低かった。`}</p>
    <p>{`また、電源をオフからオンにすると、平均100Wの電力消費が4分間ぐらい続いたのでその都度0.1円ぐらいスリープより起動にお金がかかる計算になる。`}</p>
    <p>{`電源オフにするならばコンセントを抜く覚悟で、`}<strong parentName="p"><em parentName="strong">{`基本はスリープがおすすめ`}</em></strong>{`である。`}</p>
    <h3 {...{
      "id": "白熱電球1個とパソコンどちらが電力を消費する？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%99%BD%E7%86%B1%E9%9B%BB%E7%90%831%E5%80%8B%E3%81%A8%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%A9%E3%81%A1%E3%82%89%E3%81%8C%E9%9B%BB%E5%8A%9B%E3%82%92%E6%B6%88%E8%B2%BB%E3%81%99%E3%82%8B%EF%BC%9F",
        "aria-label": "白熱電球1個とパソコンどちらが電力を消費する？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`白熱電球1個とパソコンどちらが電力を消費する？`}</h3>
    <p><strong parentName="p"><em parentName="strong">{`白熱電球の消費電力は60W程度であるので、デスクトップパソコンで軽作業をしている時よりも電力を消費している`}</em></strong>{`ことになる。`}</p>
    <p>{`白熱電球はすぐに明るくなるという性質でトイレや洗面所、風呂場の電気に良く使われている。`}</p>
    <p>{`これらの電気を消さないと怒る人がいるが、`}<strong parentName="p"><em parentName="strong">{`洗面所と風呂場で4つぐらい付けているとゲーミングノートを超えるレベルの電力を消費する`}</em></strong>{`ことになるので電気代の観点で消した方が良いことは間違えない。`}</p>
    <p>{`よく消し忘れるならば電力が10分の1のLEDに変更するのもありだろう。`}</p>
    <h3 {...{
      "id": "TITANIUMはコストに見合うのか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#TITANIUM%E3%81%AF%E3%82%B3%E3%82%B9%E3%83%88%E3%81%AB%E8%A6%8B%E5%90%88%E3%81%86%E3%81%AE%E3%81%8B%EF%BC%9F",
        "aria-label": "TITANIUMはコストに見合うのか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`TITANIUMはコストに見合うのか？`}</h3>
    <p>{`80PLUS認証でゲーミングパソコンの電源をBRONZEにしようかPLATINUMにしようか迷うことはあるかもしれない。`}</p>
    <p>{`高性能なゲーミングパソコンでゲーム時に各パーツ合計300Wの電力供給が必要だとすると、負荷が50%としてそれぞれ何Wの電力を電源に供給しなければならないかを表に示す。`}</p>
    <p><strong parentName="p">{`１日平均３時間のゲームを5年間行った時の電気代`}</strong>{`も同時に示す。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
	<thead>
		<tr>
			<th></th>
      <th>負荷50%効率</th>
			<th>必要電力(W)</th>
			<th>5年の電気代</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<th style={{
            backgroundColor: 'white',
            color: 'black'
          }}>STANDARD</th>
			<td>80%</td>
			<td>375W</td>
			<td>55434円</td>
		</tr>
		<tr>
			<th style={{
            backgroundColor: '#cd7f32',
            color: 'black'
          }}>BRONZE</th>
			<td>85%</td>
			<td>352W</td>
			<td>52173円</td>
		</tr>
		<tr>
			<th style={{
            backgroundColor: 'silver',
            color: 'black'
          }}>SILVER</th>
			<td>88%</td>
			<td>340W</td>
			<td>50395円</td>
		</tr>
		<tr>
			<th style={{
            backgroundColor: 'gold',
            color: 'black'
          }}>GOLD</th>
			<td>90%</td>
			<td>333W</td>
			<td>49275円</td>
		</tr>
		<tr>
			<th style={{
            backgroundColor: '#E5E4E2',
            color: 'black'
          }}>PLATINUM</th>
			<td>92%</td>
			<td>326W</td>
			<td>48203円</td>
		</tr>
		<tr>
			<th style={{
            backgroundColor: '#DDD6E1',
            color: 'black'
          }}>TITANIUM</th>
			<td>94%</td>
			<td>319W</td>
			<td>47178円</td>
		</tr>
	</tbody>
    </BasicTable>
    <p>{`STANDARDからTITANIUMまでで8000円の差であった。一方でTITANIUMの電源STANDARDと比べると2万円程度は高いことが多いので電気代の観点ではコストに見合わない。`}</p>
    <p>{`これを考えると電気代が6000円安くなる`}<strong parentName="p"><em parentName="strong">{`GOLDあたりが電気代と電源自体の値段の観点からコスパが良いのでは`}</em></strong>{`と思われる。`}</p>
    <p>{`ただ、良い電源はパソコンが熱を帯びにくいので、性能が安定するという観点もあり、少なくとも電気代以上に電源にお金をかける価値はあると思われる。`}</p>
    <p>{`ちなみにゲーミング用途など`}<strong parentName="p"><em parentName="strong">{`電力を食う使い方をしなければ、電気代で電源のコストを回収することは難しい`}</em></strong>{`だろう。あとは趣味の世界となる。`}</p>
    <h2 {...{
      "id": "パソコンの電気代節約のためのまとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E9%9B%BB%E6%B0%97%E4%BB%A3%E7%AF%80%E7%B4%84%E3%81%AE%E3%81%9F%E3%82%81%E3%81%AE%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "パソコンの電気代節約のためのまとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコンの電気代節約のためのまとめ`}</h2>
    <p>{`では最後にパソコンの電気代を節約する方法などをまとめておく。`}</p>
    <p>{`まずは日常でパソコンの電気代を節約する方法`}</p>
    <List className="bold" mdxType="List">
  <li>パソコンを使わない時はスリープモードにする</li>
  <li>モニタの輝度は下げる</li>
    </List>
    <p>{`あと本節では述べなかったが、バックグラウンドでプログラムが動いていてパソコンが重いなどの症状があると意図せずCPUの使用率が上がり、結果消費電力が上がってしまっていることがある。重くなった時はタスクマネージャーなどを確認したい。`}</p>
    <p>{`次にパソコンのパーツ関連で電気代を節約する方法`}</p>
    <List className="bold" mdxType="List">
  <li>TDPが低いCPUを搭載する</li>
  <li>電源容量が無駄に大きいパソコンは購入しない</li>
  <li>GPUなどパーツが不必要ならば搭載しない</li>
    </List>
    <p>{`CPUの省エネ性能は全体の電気代に最も大きく影響してくる。TDPを参考にして慎重に選ぼう。また`}<strong parentName="p">{`将来の拡張性を考えて電源容量を購入時に大きくしておくのは合理的だが、電気代的にはマイナスの影響が出る`}</strong>{`。`}</p>
    <p>{`その他調査で気付いた点のまとめ`}</p>
    <List className="bold" mdxType="List">
  <li>ゲームをするなら専用機の方が電気代は安い</li>
  <li>コンセントからでもバッテリーからでも電気代は変わらない</li>
  <li>ノートパソコンの充電代はフルにしてもたかだか1円</li>
  <li>パソコンより白熱電球の方が消費電力が大きいので電気はこまめに消そう</li>
    </List>
    <p>{`以上、長々とパソコンの電気代について書いてしまったが、温暖化への対策にもなるかもしれないのでできる限り節約につとめよう。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      